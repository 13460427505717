import React from 'react'
import tw, { styled } from 'twin.macro'
import eventsBg from '@images/events/events-bg.png'
import sectionHead from '@images/events/events-section-head.png'
import flower_01 from '@images/flower_1.png'
import flower_02 from '@images/flower_2.png'
import events from '../../content/events'
import { motion } from 'framer-motion'
import badge from '@images/events/events-badge.svg'
import { Link } from 'gatsby'
import { useBreakpoint } from '@hooks/useBreakpoint'

const SectionHead = tw.section`pb-1 xs:pb-2 pt-10 xs:pt-28`
const SectionHeadWrap = tw.div`relative max-w-master mx-auto text-center flex justify-center items-center pt-8 pb-1 xs:py-8`
const Headline = tw.div`max-w-[322px] xs:max-w-full w-full pl-24 xs:pl-60 pr-8 xs:pr-5 lg:px-0 lg:pl-10 text-xl md:text-3xl font-lobster text-gray tracking-[0.02em]`

const Section = tw.section`bg-radler lg:mx-8 lg:px-2.5 flex items-end justify-center relative pb-2 pt-2 xs:py-10`
const Wrap = tw.div`grid lg:grid-cols-2 max-w-screen-xl mx-auto relative z-10`
const Column = tw.div`relative xs:pt-10 flex flex-col justify-center items-center`
const ColumnRight = styled.div`
  ${tw`relative flex flex-col justify-center px-5 sm:pr-5 sm:pl-10 py-7 sm:py-14`}
  @media (min-width: 1024px) {
    &::after {
      ${tw`absolute left-0 w-1 mx-auto bg-right bg-repeat-y top-4 bottom-3`}
      content: '';
      background-image: linear-gradient(#ffffff 33%, rgba(255, 255, 255, 0) 0%);
      background-size: 2px 8px;
    }
  }
`

const EventsList = styled.div`
  ${tw`relative pb-5 mb-5 xs:mb-7`}
  &::after {
    ${tw`absolute bottom-0 left-0 top-auto h-1 bg-bottom bg-repeat-x right-10 md:right-1 bg-gradient-to-r`}
    content: '';
    background-image: linear-gradient(
      to right,
      #ffffff 33%,
      rgba(255, 255, 255, 0) 0%
    );
    background-size: 8px 2px;
  }
`
const EventItem = tw.div`grid grid-cols-12 font-lobster xs:pb-3 items-baseline xs:items-start`
const Day = tw.span`text-white text-15px col-span-1 xs:col-span-2`
const Date = styled.span`
  ${tw`col-span-3 xs:col-span-4 text-lg 2xl:col-span-3 text-lightBrown w-[95px] xs:w-auto text-left pl-1`}
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
`
const Title = tw.span`text-white text-15px col-span-7 xs:col-span-6 2xl:col-span-5 pl-2 xs:pl-4 2xl:pl-8`

const Heading = styled.div`
  ${tw`pb-3 text-lg xs:pb-5 xs:text-xl text-lightBrown font-lobster`}
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
`
const BandsList = tw.div``
const BandsTagCloud = styled.div`
  ${tw`flex flex-wrap items-baseline max-w-screen-xs gap-x-4 gap-y-0 sm:gap-y-1`}
`
const BandName = styled.div`
  ${tw`text-white text-15px sm:text-2xl font-lobster`}
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  flex: 0 0 auto;
`

const ButtonShape = styled(motion(Link))`
  ${tw`bg-white relative flex items-center justify-center flex-col text-radler rounded h-16 w-[188px] md:w-[280px] 
  md:h-[82px] text-22px leading-none md:text-32px font-lobster mx-auto mb-5 xs:mb-14`}
  transition: filter 0.3s ease-in-out;
  transform: translateZ(0);
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.2) 100%
  );
  &::after {
    content: '';
    ${tw`inset-1.5 border border-radler absolute z-10 opacity-70`}
  }
`

const EventsSection = () => {
  const { isScreenXs } = useBreakpoint()
  const [wasHovered, setWasHovered] = React.useState(false)
  const bandsTagCloud = [
    'Fenzl',
    'Oimara',
    'Gsindl',
    'Luegstoa C',
    'DeSchoWieda',
    'Granada',
  ]
  return (
    <>
      <SectionHead id="events">
        <SectionHeadWrap>
          <img
            className="absolute bottom-0 -left-3 xs:left-0 w-[115px] xs:w-auto"
            src={sectionHead}
            alt="FestlTour 2023"
          />
          <Headline>So kannst as ERDINGER Brauhaus live&nbsp;erleben</Headline>
        </SectionHeadWrap>
      </SectionHead>
      <Section>
        <Wrap>
          <Column>
            <img className="w-11/12" src={eventsBg} alt="FestlTour 2023" />
            <ButtonShape
              to={'/events/'}
              variants={buttonVariants}
              initial="initial"
              animate={wasHovered ? 'afterHover' : 'animate'}
              whileHover="hover"
              onHoverStart={() => {
                setWasHovered(true)
              }}
            >
              Alle Infos
            </ButtonShape>
            <img
              className="absolute h-24 -top-10 xs:top-0 sm:h-32 -right-5 xs:right-2 2xl:h-auto"
              src={badge}
              alt="Eintritt Frei!"
            />
          </Column>
          <ColumnRight>
            <EventsList>
              {events.map((item, index) => {
                return (
                  <EventItem key={`event-item-${index}`}>
                    <Day>
                      {isScreenXs ? item.head.dayMobile : item.head.day}
                    </Day>
                    <Date>
                      {isScreenXs ? item.head.dateMobile : item.head.date}
                    </Date>
                    <Title>{item.head.title}</Title>
                  </EventItem>
                )
              })}
            </EventsList>
            <BandsList>
              <Heading>Läuft ideal mit:</Heading>
              <BandsTagCloud>
                {bandsTagCloud.map((name, index) => {
                  return (
                    <BandName
                      key={`bandtag-item-${index}`}
                      className={`${index === 0 && '!text-2xl sm:!text-4xl'}`}
                    >
                      {name}
                    </BandName>
                  )
                })}
              </BandsTagCloud>
            </BandsList>
          </ColumnRight>
        </Wrap>

        <img
          tw="object-cover absolute bottom-0  left-0  scale-40 origin-bottom-left lg:scale-75 3xl:scale-100"
          src={flower_01}
          alt="Hopfen"
        />
        <img
          tw="object-cover absolute top-0  right-0  scale-40 origin-top-right lg:scale-75 3xl:scale-100"
          src={flower_02}
          alt="Hopfen"
        />
      </Section>
    </>
  )
}

const buttonVariants = {
  intial: {
    filter: 'drop-shadow(0 0 0 white)',
  },
  hover: {
    filter: 'drop-shadow(0px 0px 12px rgba(255, 255, 255, 0.8))',
    transition: {
      type: 'tween',
      ease: 'easeInOut',
    },
  },
  afterHover: {
    filter: 'drop-shadow(0 0 0 white)',
    transition: {
      type: 'tween',
      ease: 'easeInOut',
    },
  },
}

export default EventsSection
